<template>
  <base-variant-button v-bind="{ active, disabled, withBgColor: true }">
    <template #default="{ customStyle }">
      <div :style="customStyle" class="variant-btn" @click="onBtnClick">
        <div class="index variant-index">{{ alphabeticIndex }}</div>
        <div class="variant-btn__content">
          <slot></slot>
        </div>
      </div>
    </template>
  </base-variant-button>
</template>
<script>
import BaseVariantButton from "./BaseVariantButton";
import createAplphabeticIndex from "@/utils/createAlphabeticIndex";

export default {
  components: {
    BaseVariantButton,
  },
  props: ["active", "action", "disabled", "clickMethod", "variant-id", "index"],
  data() {
    return {
      alphabeticIndex: "",
    };
  },
  created() {
    this.alphabeticIndex = createAplphabeticIndex(this.index);
  },
  methods: {
    onBtnClick() {
      if (this.disabled) {
        return;
      }
      this.$emit(this.clickMethod, this.variantId);
    },
  },
};
</script>

<style scoped lang="less">
.variant-btn {
  color: #383838;
  transition: all ease 0.3s;
  font-family: ProximaNova;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 350px;
  max-width: 100%;
  min-height: 40px;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  user-select: none;
  border: 1px solid transparent;
  background-color: var(--base-color-lighter);

  @media (max-width: 560px) {
    width: 290px;
  }

  & + & {
    margin-top: 10px;
  }

  .variant-btn__content {
    overflow: hidden;
    word-break: break-word;
    white-space: break-spaces;
  }

  .variant-index {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
