<template>
  <div class="inputPoll-container">
    <div class="poll-title">
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>
    <div v-if="poll.test_questions_prompt" class="text_prompt">
      {{ poll.test_questions_prompt }}
    </div>
    <textarea
      v-model.trim="answer"
      class="inputPoll-textarea"
      name="inputPoll"
      cols="70"
      rows="10"
      :placeholder="$t('testNGames.writeYourAnswerHere')"
      :maxlength="maxLength"
    ></textarea>
    <small v-if="lettersCount >= maxLength" class="max-length-error">
      Максимальная длина ответа: {{ maxLength }} символов
    </small>
  </div>
</template>

<script>
import BasePoll from "@/components/common/polls/components/poll/BasePoll";

export default {
  extends: BasePoll,
  data() {
    return {
      answer: "",
      lettersCount: 0,
      maxLength: 250,
    };
  },
  computed: {
    errorMessage() {
      return this.$t("testNGames.theAnswerCantBeEmpty");
    },
    hasError() {
      return this.answer.length < 1 || this.answer.trim().length < 1;
    },
    sendData() {
      return this.answer;
    },
    allowedLetterLength() {
      return this.poll?.props?.is_show_max_length ? this.poll.props.max_answer_length_value : 9999;
    },
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },
  watch: {
    answer(newValue) {
      if (newValue && newValue.length > this.allowedLetterLength) {
        this.answer = newValue.substr(0, this.allowedLetterLength);
      }
      this.lettersCount = this.answer.length;
    },
  },
  methods: {
    clearAnswers() {
      this.answer = "";
    },
    checkErrors() {
      return this.answer.length < 1 || this.answer.trim().length < 1;
    },
  },
};
</script>

<style scoped lang="scss">
.poll-title {
  position: relative;
  padding-bottom: 25px;
  margin: 15px 15px 25px 15px;
  font-size: 24px;
  //color: #232323;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }
}

.letters-counter {
  margin-top: 5px;
  font-size: 14px;

  &.filled {
    color: red;
  }
}

.inputPoll-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 0 9px;
}

textarea {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  resize: none;
  border: solid 1px #979797;
  border-radius: 4px;
}

.text_prompt {
  position: relative;
  padding-bottom: 5px;
  margin: 15px 15px 25px;
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: break-spaces;
}
.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}

.max-length-error {
  color: red;
}
</style>
