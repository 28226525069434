<template>
  <div>Implement template for poll</div>
</template>
<script>
import {mapMutations, mapActions, mapState} from "vuex";
import { games, polls, presentation, room } from "@/store/modules/store.namespaces";
import { SEND_NEW_ANSWER } from "@/store/modules/polls/action-types";
import { SET_TEST_SLIDE_ANSWERED } from "@/store/modules/presentation/mutation-types";

export default {
  props: {
    poll: {
      type: Object,
      required: true,
    },
    pollChain: {
      type: Object,
      default: () => ({}),
    },
    moduleType: {
      type: String,
      required: true,
    },
    isMountainGame: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answers: [],
      time: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState(room, {
      themeStyleColors: state => state.styleColors,
    }),
    hasError() {
      return this.answers.length < 1;
    },
    errorPollMessage() {
      return "";
    },
    sendData() {
      return this.answers;
    },
    /**
     * Показатель последнего вопроса.
     * Если вопрос был последним то вернет true.
     *
     * @returns {boolean} Возвращает показатель был ли вопрос последним.
     */
    isLastQuestion() {
      // return this.pollChain.polls?.length === this.pollChain.step + 1;
      return this.pollChain.questionsNum === this.pollChain.step + 1;
    },
  },

  mounted() {
    this.startTimer();

    this.createPollTitleClass();
  },
  destroyed() {
    this.stopTimer();
  },

  methods: {
    ...mapMutations(presentation, {
      setTestSlideAnswered: SET_TEST_SLIDE_ANSWERED,
    }),
    ...mapActions(polls, {
      sendPollAnswer: SEND_NEW_ANSWER,
    }),

    /**
     * Тут метод ответа на вопрос
     *
     * @param isFromSlide
     * @param slideId
     */
    // async sendAnswer(action, isFromSlide, slideId) {
    //   /**TODO: Why computed in extended components not working? */
    //   if (this.checkErrors()) {
    //     return this.handleError();
    //   }
    //   this.clearError();
    //   await this.dispatchSendAction(action);
    //   this.clearAnswers();
    //   if (isFromSlide && this.isLastQuestion) {
    //     this.setTestSlideAnswered(slideId);
    //   }
    // },

    /**
     * Новый ответ на вопрос.
     * TODO: Добавить параметры test_id и time, переделать везде такой метод
     * TODO: Перепроверить варианты формы ответа на вопросы
     * TODO: Доделать метод ответа на вопрос и очистить все лишнее.
     * TODO: Перепроверить все изменения.
     *
     * @param {string} action - тип ответа на вопрос.
     * @param {boolean} isFromSlide - Из слайда ли данный ответ
     * @param {number} slideId - ID слайда, на котором пользователь ответил.
     */
    async sendAnswer(action, isFromSlide, slideId) {
      if (this.checkErrors()) {
        // this.$notify({
        //   group: "roomSystem",
        //   type: "error",
        //   duration: 5000,
        //   title: this.$t("common.error"),
        //   text: this.$t("Tests.hasNoOneAnswer"),
        // });
        return this.handleError();
      }
      this.stopTimer();
      this.sendPollAnswer({
        type: this.moduleType,
        // Если это повторный вопрос - всегда отправлять is_last_question: true
        is_last_question:
          (this.pollChain?.isRepeated && this.pollChain?.timerDelta) || this.isLastQuestion,
        // Костыль. При отправке на бэк 0, бэк воспринимает вопрос пропущенным, минимальное время ответа на вопрос 1 секунда
        user_test_answer_time: Math.max(1, this.time),
        poll_id: this.poll?.id,
        test_id: this.pollChain?.id,
        variants: this.sendData,
        moduleType: this.moduleType,
      });

      // Очистка для следующего вопроса
      this.clearError();
      this.clearAnswers();

      if (isFromSlide && this.isLastQuestion) {
        this.setTestSlideAnswered(slideId);
      }
      return "";
    },
    /**
     * Метод пропуска вопроса
     */
    async skipQuestion() {
      this.sendPollAnswer({
        type: this.moduleType,
        // Если это повторный вопрос - всегда отправлять is_last_question: true
        is_last_question: this.isLastQuestion || (this.poll?.isRepeated && this.poll?.timerDelta),
        user_test_answer_time: this.time,
        poll_id: this.poll?.id,
        test_id: this.pollChain?.id,
        variants: this.sendData,
        skipQuestion: true,
        moduleType: this.moduleType,
      });
      // Очистка для следующего вопроса
      this.clearError();
      this.clearAnswers();
    },
    handleError() {
      this.$emit("error", this.errorPollMessage);
    },
    clearError() {
      this.$emit("clearError");
    },
    dispatchSendAction(action) {
      if (this.isMountainGame) {
        return this.$store.dispatch(`${games}/${action}`, this.sendData);
      }
      return this.$store.dispatch(`${polls}/${action}`, {
        type: this.moduleType,
        payload: this.sendData,
      });
    },
    clearAnswers() {
      this.answers = [];
    },
    checkErrors() {
      return this.answers.length < 1;
    },
    // Вынести таймер в отдельный компонент
    // Разобраться с сохранением таймера если пользователь перезапустил страницу
    startTimer() {
      this.timer = setInterval(() => {
        this.time = +this.time + 1;
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },

    createPollTitleClass() {
      let style = document.createElement('style');
      style.innerHTML = `.poll-title { color: ${this.themeStyleColors.innerHeadersColor}; }`;
      document.getElementsByTagName('head')[0].appendChild(style);
    },
  },
};
</script>

<style lang="scss">
.poll-title {
  font-size: 24px;
  text-align: center;
  white-space: pre-wrap;
}
</style>
