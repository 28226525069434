<template>
  <div>
    <div class="poll-title" :class="{ 'poll-title--without-timer': !$scopedSlots.timer }">
      {{ poll.title }}
      <div v-if="pollImg" class="poll-img-wrapper" :class="{ 'poll-img-wrapper--error': imgError }">
        <img v-if="!imgError" class="poll-img" :src="pollImg" @error="imgError = true" />
        <ws-icon v-if="imgError" xxl color="white" class="absolute-center">
          image
        </ws-icon>
      </div>
      <slot name="timer"></slot>
    </div>
    <div v-if="poll.test_questions_prompt" class="text_prompt">
      {{ poll.test_questions_prompt }}
    </div>
    <variant-btn
      v-for="(variant, index) in poll.variants"
      :key="variant.id"
      class="variant-btn"
      :disabled="isDisabled(variant.id)"
      :variant-id="variant.id"
      :index="index"
      :active="isActive(variant.id)"
      click-method="set-variant"
      @set-variant="setVariant"
    >
      {{ variant.title }}
    </variant-btn>
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import VariantBtn from "../common/VariantBtn";
import BasePoll from "./BasePoll";

export default {
  components: {
    VariantBtn,
    WsIcon,
  },
  extends: BasePoll,
  data() {
    return {
      imgError: false,
    };
  },
  computed: {
    errorMessage() {
      return this.$t("testNGames.youHaveToChooseAtLeastOneAnswer");
    },
    pollImg() {
      return this.poll.test_questions_image_url;
    },
  },
  watch: {
    pollImg() {
      if (this.imgError) {
        this.imgError = false;
      }
    },
  },
  methods: {
    setVariant(id) {
      if (+this.poll.props.max_answers === 1) {
        this.answers = [id];
      } else {
        this.isActive(id)
          ? this.answers.splice(this.answers.indexOf(id), 1)
          : this.answers.push(id);
      }
    },
    isActive(id) {
      return this.answers.indexOf(id) > -1;
    },
    isDisabled(id) {
      return (
        +this.poll.max_answers_number === this.answers.length &&
        !this.isActive(id) &&
        !(!this.poll.max_answers_number || +this.poll.max_answers_number < 1)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.poll-title {
  position: relative;
  padding-bottom: 25px;
  margin: 15px 15px 25px;
  font-size: 24px;
  //color: #232323;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;

  @media (max-width: 1280px) {
    margin: 7.5px 7.5px 12.5px;
  }

  &--without-timer {
    padding-bottom: 0;
  }
}

.king-poll {
  margin: auto;
}

.variant-btn {
  margin: 0 auto;
}

.poll-img-wrapper {
  position: relative;
  max-width: 350px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px -4px rgba($black, 0.2);

  &--error {
    width: 240px;
    height: 240px;
    background-color: rgba($gray, 0.6);
  }

  .poll-img {
    width: 100%;
  }
}

.poll-img {
  display: block;
  max-width: 100%;

  @include object-fit();
}

.text_prompt {
  position: relative;
  padding-bottom: 5px;
  margin: 15px 15px 25px;
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
  letter-spacing: normal;
  word-break: break-word;
  white-space: break-spaces;
}
</style>
