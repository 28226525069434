<script>
import { mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";

export default {
  props: ["active", "disabled", "withBgColor"],
  computed: {
    ...mapState(room, ["styleColors"]),
    customStyle() {
      const {
        baseColor,
        baseBgColor,
        buttonActiveTextColor,
        baseTextColor,
      } = this.styleColors;
      let bgColor = "#fff";
      if (this.withBgColor) {
        bgColor = this.active ? baseColor : baseBgColor;
      }
      return {
        backgroundColor: bgColor,
        borderColor: baseColor,
        color: this.active ? buttonActiveTextColor : baseTextColor,
        cursor: this.disabled ? "initial" : "pointer",
      };
    },
  },
  render() {
    return this.$scopedSlots.default({ customStyle: this.customStyle });
  },
};
</script>
